const link = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Videos',
    url: '/cadastro/video',
  },
  {
    name: 'Categorias',
    url: '/cadastro/categoria',
  },
];

export default link;
